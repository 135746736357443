'use client'

import { useEffect, useState } from 'react'

import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import Script from 'next/script'

declare const window: Window & { dataLayer: any[]; _ccRun: boolean }

const updateCookieConsent = () => {
  CookieConsent.showPreferences()
}

const resetCookieConsent = () => {
  CookieConsent.reset(true)
}

const listenForConsent = (state: any, gtmId: string) => {
  if (window._ccRun) return

  window.dataLayer = window.dataLayer || []
  function gtag(...args: any[]) {
    window.dataLayer.push(args)
  }

  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
  })

  state.setLoadScript(true)

  gtag('js', new Date())
  gtag('config', gtmId)

  const updateGtagConsent = () => {
    gtag('consent', 'update', {
      ad_storage: CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
      ad_user_data: CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
      ad_personalization: CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
      analytics_storage: CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
      functionality_storage: CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
      personalization_storage: CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
      security_storage: 'granted', //necessary
    })
  }

  const onConsent = () => {
    updateGtagConsent()
  }

  const onChange = () => {
    updateGtagConsent()
  }

  window.addEventListener('cc:onConsent', onConsent)
  window.addEventListener('cc:onChange', onChange)

  return () => {
    window.removeEventListener('cc:onConsent', onConsent)
    window.removeEventListener('cc:onChange', onChange)
  }
}

const CookieConsentComponent = ({ gtmId }: { gtmId: string }) => {
  const [loadScript, setLoadScript] = useState(false)

  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    const unregister = listenForConsent({ setLoadScript }, gtmId)
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'cloud inline',
          position: 'bottom center',
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functional: {},
        analytics: {},
        // performance: {},
        advertisement: {},
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Cookies',
              description:
                'Vi använder cookies för att ge dig en bättre upplevelse och för att analysera hur vår webbplats används.',
              acceptAllBtn: 'Tillåt alla cookies',
              acceptNecessaryBtn: undefined,
              showPreferencesBtn: 'Inställningar',
            },
            preferencesModal: {
              title: 'Inställningar',
              acceptAllBtn: 'Tillåt alla cookies',
              acceptNecessaryBtn: undefined,
              savePreferencesBtn: 'Spara inställningar',
              closeIconLabel: 'Stäng fönster',
              sections: [
                {
                  title: 'Cookies',
                  description: `Vi använder cookies för att ge dig en bättre upplevelse och för att analysera hur vår webbplats används.`,
                },
                {
                  title: 'Nödvändiga',
                  description:
                    'Dessa cookies är nödvändiga för att webbplatsen ska fungera och kan inte stängas av i våra system. De är vanligtvis bara inställda som svar på åtgärder som du gjort som utgör en begäran om tjänster, till exempel inställning av dina personliga preferenser, inloggning eller fyllning av formulär. Du kan ställa in din webbläsare för att blockera eller varna dig om dessa cookies, men vissa delar av webbplatsen fungerar inte då. Dessa cookies lagrar inte någon personligt identifierbar information.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Funktionella',
                  description:
                    'Dessa cookies gör det möjligt för webbplatsen att tillhandahålla förbättrad funktionalitet och personlig anpassning. De kan fastställas av oss eller av tredjepartsleverantörer vars tjänster vi har lagt till på våra sidor. Om du inte tillåter dessa cookies kanske vissa eller alla av dessa tjänster inte fungerar som de ska.',
                  linkedCategory: 'functional',
                },
                {
                  title: 'Prestanda',
                  description:
                    'Dessa cookies tillåter oss att räkna besök och trafikkällor så att vi kan mäta och förbättra prestandan på vår webbplats. De hjälper oss att veta vilka sidor som är mest och minst populära och se hur besökare flyttar runt på webbplatsen. All information som dessa cookies samlar är aggregerade och därför anonyma. Om du inte tillåter dessa cookies vet vi inte när du har besökt vår webbplats.',
                  linkedCategory: 'analytics',
                },
                // {
                //   title: 'Performance',
                //   description: 'Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.',
                //   linkedCategory: 'performance',
                // },
                {
                  title: 'Marknadsföring',
                  description:
                    'Dessa cookies ställs in på vår webbplats av våra annonseringspartners. De kan användas av dessa företag för att skapa en profil av dina intressen och visa dig relevanta annonser på andra webbplatser. De lagrar inte direkt personlig information, men är baserade på unikt identifierande av din webbläsare och internet-enhet. Om du inte tillåter dessa cookies kommer du att erfara mindre riktade annonser.',
                  linkedCategory: 'advertisement',
                },
                {
                  title: 'Mer information',
                  description:
                    'Om du har några frågor om vår användning av cookies, vänligen kontakta oss på info@booet.se',
                },
              ],
            },
          },
        },
      },
    })

    return unregister
  }, [])

  return (
    <>
      {loadScript && (
        <Script async strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`} />
      )}
    </>
  )
}

export { updateCookieConsent, resetCookieConsent, CookieConsentComponent }
